import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {isMobile, isTablet} from "react-device-detect";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loading from "../components/Loading";

import {uploadFile} from "../routes/Routes";
import {loadLocations} from "../common/Common";

import Mail from '../assets/images/Component-mail.png';
import Phone from '../assets/images/Component-phone.png';
import Location from '../assets/images/Component-map.png';

import '../assets/css/ContactUs.css';

function ContactUsPage() {
    const [loading, setLoading] = useState(false),
        [success, setSuccess] = useState({}),
        [error, setError] = useState({}),
        [locations, setLocations] = useState([]),
        [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        loadLocations().then(data => {
            setLocations(data);
            setLoadingContent(false);
        }).catch(() => null);
    }, []);

    const locationBody = (locations) => {
        let body = [];
        locations.forEach((location, index) => {
            body.push(
                <div className="location" key={"Location" + index}>
                    <img src={location.image_url} alt={location.name}/>
                    <div className="overlay">
                        <h1 className="location-name">{location.name}</h1>
                        <p className="location-address">{location.address}</p>
                        <Link to={location.link} className="nav-link location-link" target="_blank">
                            Go to location
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                 className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            );
        });
        if (!(isMobile || isTablet)) {
            if (body.length < 3) {
                for (let i = body.length; i < 3; i++) {
                    body.push(<div className="location" key={"Location" + i}/>);
                }
            }
        }
        return body;
    }

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData();
        data.append('first_name', event.target.firstName.value);
        data.append('last_name', event.target.lastName.value);
        data.append('phone', event.target.phone.value);
        data.append('email', event.target.email.value);
        data.append('country', event.target.country.value);
        data.append('company', event.target.company.value);
        data.append('message', event.target.message.value);
        uploadFile(data, '/api/contact-us', null).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    if (!loadingContent) {
        return (
            <div>
                <div style={{backgroundColor: "white", marginTop: "10vh"}}>
                    <div className="d-flex justify-content-center mb-5 container contact-us-container">
                        <div className="container">
                            <h5 style={{color: "#777777"}} className="home-title mb-3 text-uppercase about-us-title">Home &gt; Contact Us</h5>
                            <h1 className="home-subtitle mb-5">Contact Us</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="d-flex justify-content-center contact-details" style={{margin: "10% 0"}}>
                            <div className="d-flex flex-row gap-4" style={{width: "100%", alignItems: "center"}}>
                                <div style={{width: "calc(100% / 3)", alignSelf: "center"}}>
                                    <div className="d-flex flex-row" style={{alignItems: "center"}}>
                                        <div>
                                            <img src={Mail} className="me-4 con-imgs" alt='' />
                                        </div>
                                        <div>
                                            <h4 className="Contactus-subtitle">Email Address</h4>
                                            <h6 className="Contactus-details">sales@mitshu.ae</h6>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "calc(100% / 3)", alignSelf: "center"}}>
                                    <div className="d-flex flex-row" style={{alignItems: "center"}}>
                                        <div>
                                            <img src={Phone} alt='' className="me-4 con-imgs"/>
                                        </div>
                                        <div>
                                            <h4 className="Contactus-subtitle">Phone Number</h4>
                                            <h6 className="Contactus-details">
                                                +971 45 80 9289
                                            </h6>
                                            <h6 className="Contactus-details">
                                                +971 56 863 7718
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "calc(100% / 3)", alignSelf: "center"}}>
                                    <div className="d-flex flex-row" style={{alignItems: "center"}}>
                                        <div>
                                            <img src={Location} alt='' className="me-4 con-imgs"/>
                                        </div>
                                        <div>
                                            <h4 className="Contactus-subtitle">Location</h4>
                                            <h6 className="Contactus-details">
                                                Qufaidi Building S1, Al Buteen, Murshid
                                                Bazaar, Deira, Dubai - U.A.E
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="text-center mb-5">
                                <h1 className="Contactus-message">Send us a message</h1>
                                <p className="Contactus-message-details">
                                    Please do not hesitate to get in touch with us to share your insights. Our dedicated
                                    customer support team is readily available to promptly address any questions or
                                    comments you might wish to convey.
                                </p>
                            </div>
                        </div>

                        <form onSubmit={formSubmit} className="contact-us-form">
                            <div className="d-flex justify-content-center mb-5">
                                <div className="d-flex flex-row gap-5">
                                    <div>
                                        <div className="d-flex flex-column gap-2">
                                            <div>
                                                <h1 className="contactus-first-name">
                                                    First Name
                                                    <label style={{color: "#E1211D"}}> * </label>
                                                </h1>
                                                <input className="contactus-input" id="firstName" name="firstName"
                                                       placeholder="John" required/>
                                            </div>
                                            <div>
                                                <h1 className="contactus-first-name">
                                                    Last Name
                                                    <label style={{color: "#E1211D"}}> * </label>
                                                </h1>
                                                <input className="contactus-input" id="lastName" name="lastName"
                                                       placeholder="Smith" required/>
                                            </div>
                                            <div>
                                                <h1 className="contactus-first-name">
                                                    Phone Number
                                                    <label style={{color: "#E1211D"}}> * </label>
                                                </h1>
                                                <input className="contactus-input" type="phone" id="phone" name="phone"
                                                       placeholder="202-555-0124" required/>
                                            </div>
                                            <div>
                                                <h1 className="contactus-first-name">
                                                    Email Address
                                                    <label style={{color: "#E1211D"}}> * </label>
                                                </h1>
                                                <input className="contactus-input" type="email" id="email" name="email"
                                                       placeholder="john@gmail.com" required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="d-flex flex-column gap-2">
                                            <div>
                                                <h1 className="contactus-first-name">
                                                    Country
                                                    <label style={{color: "#E1211D"}}> * </label>
                                                </h1>
                                                <input className="contactus-input" id="country" name="country"
                                                       placeholder="United States" required/>
                                            </div>
                                            <div>
                                                <h1 className="contactus-first-name">Company</h1>
                                                <input className="contactus-input" id="company" name="company"
                                                       placeholder="ABC Inc"/>
                                            </div>
                                            <div>
                                                <h1 className="contactus-first-name" style={{ marginTop: "13px" }}>Message</h1>
                                                <textarea className="contactus-input contactus-txt" id="message"
                                                          name="message" placeholder="Let us know how we can help you,"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error.error &&
                            <div className="alert alert-danger" role="alert">
                                {error.errorMessage}
                            </div>}
                            {success.success &&
                            <div className="alert alert-success" role="alert">
                                {success.successMessage}
                            </div>}
                            <div className="d-flex justify-content-center mb-5">
                                <button className="btn Contactus-btn mb-5 blue-button" type="submit">
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    send message
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="currentColor"
                                         className="bi bi-send ml-1" viewBox="0 0 16 18">
                                        <path
                                            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                    </svg>
                                </button>
                            </div>
                        </form>

                        <div className="location-container">
                            <div className="justify-content-center mb-5">
                                <h1 className="text-center our-location">Our Locations</h1>
                                <p className="text-center our-location-details">
                                    Originating in Sri Lanka, our journey has encompassed expansion into Dubai and the
                                    shores of Australia. Across these diverse locations, our unwavering commitment
                                    remains to provide our valued customers with a consistently seamless brand
                                    experience.
                                </p>
                            </div>
                            <Slider dots={true} arrows={false} autoplay={true} infinite={true} pauseOnHover={true}
                                    slidesToShow={(isMobile || isTablet) ? 1 : 3}
                                    slidesToScroll={(isMobile || isTablet) ? 1 : 3}>
                                {locationBody(locations)}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default ContactUsPage
