import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";

import Loading from "../components/Loading";
import Pagination from "../components/pagination/Pagination";

import '../assets/css/ProdcutPage2.css';

import {
    loadProductCategoryTags,
    loadProducts,
    loadSearchFromProductCategoryTagsResults,
    loadSearchResults
} from "../common/Common";

function ProductsPage() {
    const [products, setProducts] = useState([]),
        [filteredProducts, setFilteredProducts] = useState([]),
        [productCategoryTags, setProductCategoryTags] = useState([]),
        [loadingContent, setLoadingContent] = useState(true),
        [searchQuery, setSearchQuery] = useState(decodeURI(window.location.href.split('/').at(-1))),
        [selectedProductCategoryTags, setSelectedProductCategoryTags] = useState([]),
        [colors, setColors] = useState([]),
        [selectedColors, setSelectedColors] = useState([]),
        [sizes, setSizes] = useState([]),
        [selectedSizes, setSelectedSizes] = useState([]),
        [pageSize, setPageSize] = useState(12),
        [currentPage, setCurrentPage] = useState(1),
        [sortBy, setSortBy] = useState("Relativity"),
        location = useLocation();

    useEffect(() => {
        loadProductCategoryTags().then(data => {
            setProductCategoryTags(data);
            if (searchQuery === 'products') {
                if (location.state && location.state.productCategoryTag) {
                    let tempSelectedProductCategoryTags = [];
                    tempSelectedProductCategoryTags.push(location.state.productCategoryTag.id);
                    setSelectedProductCategoryTags(tempSelectedProductCategoryTags);
                    let values = {product_category_tags: tempSelectedProductCategoryTags};
                    loadSearchFromProductCategoryTagsResults(values).then(data => {
                        setProducts(data);
                        setFilteredProducts(data);
                        setLoadingContent(false);
                    }).catch(() => null);
                } else {
                    loadProducts().then(data => {
                        setProducts(data);
                        setFilteredProducts(data);
                        setLoadingContent(false);
                    }).catch(() => null);
                }
            } else {
                loadSearchResults(searchQuery).then(data => {
                    setProducts(data);
                    setFilteredProducts(data);
                    setLoadingContent(false);
                }).catch(() => null);
            }
        }).catch(() => null);
    }, []);

    useEffect(() => {
        if (location.state && location.state.productCategoryTag) {
            setLoadingContent(true);
            setSearchQuery('products');
            let tempSelectedProductCategoryTags = [];
            tempSelectedProductCategoryTags.push(location.state.productCategoryTag.id);
            setSelectedProductCategoryTags(tempSelectedProductCategoryTags);
            let values = {product_category_tags: tempSelectedProductCategoryTags};
            loadSearchFromProductCategoryTagsResults(values).then(data => {
                setProducts(data);
                setSelectedColors([]);
                setSelectedSizes([]);
                filterProducts([], [], data);
                setLoadingContent(false);
            }).catch(() => null);
        }
    }, [location]);

    useEffect(() => {
        let tempColors = [],
            tempSizes = [];
        products.forEach((product) => {
            JSON.parse(product.colors).forEach((color) => {
                if (!tempColors.includes(color)) {
                    tempColors.push(color);
                }
            });
            JSON.parse(product.sizes).forEach((size) => {
                if (!tempSizes.includes(size)) {
                    tempSizes.push(size);
                }
            });
            setColors(tempColors);
            setSizes(tempSizes);
        });
    }, [products]);

    const filterProducts = (selectedColors, selectedSizes, products) => {
        let tempFilteredProducts = [];
        if (selectedColors.length > 0 || selectedSizes.length > 0) {
            products.forEach((product) => {
                if (selectedColors.length > 0 && selectedSizes.length > 0) {
                    let filteredProductsOnColor = null;
                    selectedColors.forEach((color) => {
                        if (JSON.parse(product.colors).includes(color)) {
                            filteredProductsOnColor = product;
                        }
                    });
                    if (filteredProductsOnColor) {
                        selectedSizes.forEach((size) => {
                            if (JSON.parse(filteredProductsOnColor.sizes).includes(size)) {
                                if (!tempFilteredProducts.includes(product)) {
                                    tempFilteredProducts.push(product);
                                }
                            }
                        });
                    }
                } else if (selectedColors.length > 0) {
                    selectedColors.forEach((color) => {
                        if (JSON.parse(product.colors).includes(color)) {
                            if (!tempFilteredProducts.includes(product)) {
                                tempFilteredProducts.push(product);
                            }
                        }
                    });
                } else if (selectedSizes.length > 0) {
                    selectedSizes.forEach((size) => {
                        if (JSON.parse(product.sizes).includes(size)) {
                            if (!tempFilteredProducts.includes(product)) {
                                tempFilteredProducts.push(product);
                            }
                        }
                    });
                }
            });
        } else {
            tempFilteredProducts = products;
        }
        setFilteredProducts(tempFilteredProducts);
    }

    const handleCheckBoxFunction = (id) => {
        let tempSelectedProductCategoryTags = selectedProductCategoryTags;
        if (tempSelectedProductCategoryTags.includes(id)) {
            tempSelectedProductCategoryTags = tempSelectedProductCategoryTags.filter(function (element) {
                return element !== id
            });
        } else {
            tempSelectedProductCategoryTags.push(id);
        }
        if (tempSelectedProductCategoryTags.length === 0) {
            setLoadingContent(true);
            if (searchQuery === 'products') {
                loadProducts().then(data => {
                    setProducts(data);
                    filterProducts(selectedColors, selectedSizes, data);
                    setLoadingContent(false);
                }).catch(() => null);
            } else {
                loadSearchResults(searchQuery).then(data => {
                    setProducts(data);
                    filterProducts(selectedColors, selectedSizes, data);
                    setLoadingContent(false);
                }).catch(() => null);
            }
        } else {
            setLoadingContent(true);
            let values = {product_category_tags: tempSelectedProductCategoryTags};
            values.searchQuery = searchQuery === 'products' ? null : searchQuery;
            loadSearchFromProductCategoryTagsResults(values).then(data => {
                setProducts(data);
                filterProducts(selectedColors, selectedSizes, data);
                setLoadingContent(false);
            }).catch(() => null);
        }
        setSelectedProductCategoryTags([...tempSelectedProductCategoryTags]);
    };

    const productCategoryTagBody = (productCategoryTags) => {
        let body = [];
        productCategoryTags.forEach((productCategoryTag, index) => {
            body.push(
                <div className="form-check mb-3" key={index}>
                    <input className="form-check-input category-checkbox" type="checkbox" value={productCategoryTag.id}
                           id={productCategoryTag.id}
                           data-bs-target="#filterModal" data-bs-dismiss="modal"
                           checked={selectedProductCategoryTags.includes(productCategoryTag.id)}
                           onChange={(event) => handleCheckBoxFunction(productCategoryTag.id)}/>
                    <label className="form-check-label category-selection" htmlFor={productCategoryTag.id}>
                        {productCategoryTag.name}
                    </label>
                </div>
            );
        });
        return body;
    }

    const productBody = (products) => {
        let body = [];
        products.forEach((product, index) => {
            body.push(
                <div className="col-12 col-md-4 product-list-product" key={index}>
                    <Link to={"/product/" + product.id} style={{textDecoration: "none"}}>
                        <div className="product-card">
                            <img className="card-img-top" src={product.image_url} alt={product.name}/>
                            <div className="card-body" style={{padding: "16px 0px"}}>
                                <h5 className="card-title" style={{textAlign: "left"}}>{product.name}</h5>
                                <p className="card-text">{product.currency} {product.price}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        });
        return body;
    }

    const colorBody = (colors) => {
        let body = [];
        colors.forEach((color, index) => {
            body.push(
                <div className="form-check form-check-inline" key={index}>
                    <input type="checkbox" className="form-check-input product-color-black" required
                           name="color" id={"color" + index} value={color}
                           checked={selectedColors.includes(color)}
                           onChange={() => {
                               let tempSelectedColors = selectedColors;
                               if (tempSelectedColors.includes(color)) {
                                   tempSelectedColors = tempSelectedColors.filter(function (element) {
                                       return element !== color
                                   });
                               } else {
                                   tempSelectedColors.push(color);
                               }
                               setSelectedColors([...tempSelectedColors]);
                               filterProducts(tempSelectedColors, selectedSizes, products);
                           }}
                           style={{backgroundColor: color}}/>
                </div>
            );
        });
        return body;
    }

    const sizeBody = (sizes) => {
        let body = [];
        sizes.forEach((size, index) => {
            body.push(
                <button key={index}
                        className={(selectedSizes.includes(size) ? "selected-size-button " : "") + "btn me-2 volume-btn rounded-circle btn-outline-dark"}
                        style={{padding: "2% 4%"}}
                        onClick={() => {
                            let tempSelectedSizes = selectedSizes;
                            if (tempSelectedSizes.includes(size)) {
                                tempSelectedSizes = tempSelectedSizes.filter(function (element) {
                                    return element !== size
                                });
                            } else {
                                tempSelectedSizes.push(size);
                            }
                            setSelectedSizes([...tempSelectedSizes]);
                            filterProducts(selectedColors, tempSelectedSizes, products);
                        }}>
                    {size}L
                </button>
            );
        });
        return body;
    }

    if (!loadingContent) {
        let tempFilteredProducts = JSON.parse(JSON.stringify(filteredProducts));
        if (sortBy === "Price: Low to High") {
            tempFilteredProducts.sort((a, b) => a.price - b.price);
        } else if (sortBy === "Price: High to Low") {
            tempFilteredProducts.sort((a, b) => b.price - a.price);
        }
        const firstPageIndex = (currentPage - 1) * pageSize,
            lastPageIndex = firstPageIndex + pageSize,
            currentPageProducts = tempFilteredProducts.slice(firstPageIndex, lastPageIndex);
        return (
            <div style={{backgroundColor: "white", marginTop: "5vh", marginBottom: "5vh"}}>
                <div className="container product-list-container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            {/* Category */}
                            <h2 className="my-4">By Category</h2>
                            {productCategoryTags.length > 0 ?
                                productCategoryTagBody(productCategoryTags)
                                : <p className='no-products' style={{marginTop: 0, textAlign: "left", fontSize: 18}}>
                                    No Categories Found
                                </p>
                            }

                            {/* Color */}
                            <h2 className="my-4">By Color</h2>
                            {colors.length > 0 ?
                                colorBody(colors)
                                : <p className='no-products' style={{marginTop: 0, textAlign: "left", fontSize: 18}}>
                                    No Colors Found
                                </p>
                            }

                            {/* Size */}
                            <h2 className="my-4">By Size</h2>
                            <div className="size-circle-buttons">
                                {sizes.length > 0 ?
                                    sizeBody(sizes)
                                    :
                                    <p className='no-products' style={{marginTop: 0, textAlign: "left", fontSize: 18}}>
                                        No Sizes Found
                                    </p>
                                }
                            </div>
                        </div>

                        {/* Product */}
                        <div className="col-12 col-md-7 ">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex flex-row sort-product-container">
                                        {/* Showing 1 - 9 of 72 Results */}
                                        <div className="showing-results mr-auto p-2" style={{height: "70px"}}>
                                            <p className="category-selection" style={{lineHeight: "65px"}}>
                                                Showing {firstPageIndex + 1} - {filteredProducts.length < lastPageIndex ? filteredProducts.length : lastPageIndex} of {filteredProducts.length} Results
                                            </p>
                                        </div>

                                        {/* Sort By */}
                                        <div className="sort-by-dropdown p-2" style={{height: "70px"}}>
                                            <button className="btn product-dropdown-button" type="button"
                                                    id="dropdownSortMenuButton" data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                                SORT BY
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     fill="currentColor" className="bi bi-chevron-down ml-2"
                                                     viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownSortMenuButton">
                                                <a className={"dropdown-item" + (sortBy === "Relativity" ? " active" : "")}
                                                   onClick={() => setSortBy("Relativity")}>
                                                    Relativity
                                                </a>
                                                <a className={"dropdown-item" + (sortBy === "Price: Low to High" ? " active" : "")}
                                                   onClick={() => setSortBy("Price: Low to High")}>
                                                    Price: Low to High
                                                </a>
                                                <a className={"dropdown-item" + (sortBy === "Price: High to Low" ? " active" : "")}
                                                   onClick={() => setSortBy("Price: High to Low")}>
                                                    Price: High to Low
                                                </a>
                                            </div>
                                        </div>

                                        {/* Filter */}
                                        <div className="filter-dropdown p-2" style={{height: "70px"}}>
                                            <button className="btn product-dropdown-button mobile-filter-button"
                                                    type="button" data-bs-toggle="modal" data-bs-target="#filterModal">
                                                FILTER
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     fill="currentColor" className="bi bi-filter ml-1"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                                                </svg>
                                            </button>
                                            <button className="btn product-dropdown-button desktop-filter-button"
                                                    type="button" id="dropdownFilterMenuButton" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                FILTER
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     fill="currentColor" className="bi bi-filter ml-1"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                                                </svg>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownFilterMenuButton">
                                                <a className={"dropdown-item" + (pageSize === 12 ? " active" : "")}
                                                   onClick={() => setPageSize(12)}>12</a>
                                                <a className={"dropdown-item" + (pageSize === 24 ? " active" : "")}
                                                   onClick={() => setPageSize(24)}>24</a>
                                                <a className={"dropdown-item" + (pageSize === 36 ? " active" : "")}
                                                   onClick={() => setPageSize(36)}>36</a>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{background: "#B6B6B6", height: "1px", border: "none"}}/>
                                </div>
                                {filteredProducts.length > 0 ?
                                    productBody(currentPageProducts)
                                    : <p className='no-products' style={{marginTop: 0}}>No Products Found</p>
                                }

                                {/* Pagination */}
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={filteredProducts.length}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="filterModal" tabIndex="-1" aria-labelledby="filterModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "80%"}}>
                        <div className="modal-content">
                            <div className="modal-header" style={{border: "none"}}>
                                <button type="button" className="btn btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 col-md-4">
                                    {/* Category */}
                                    <h2 className="my-4">By Category</h2>
                                    {productCategoryTags.length > 0 ?
                                        productCategoryTagBody(productCategoryTags)
                                        : <p className='no-products'
                                             style={{marginTop: 0, textAlign: "left", fontSize: 18}}>
                                            No Categories Found
                                        </p>
                                    }

                                    {/* Color */}
                                    <h2 className="my-4">By Color</h2>
                                    {colors.length > 0 ?
                                        colorBody(colors)
                                        : <p className='no-products'
                                             style={{marginTop: 0, textAlign: "left", fontSize: 18}}>
                                            No Colors Found
                                        </p>
                                    }

                                    {/* Size */}
                                    <h2 className="my-4">By Size</h2>
                                    <div className="size-circle-buttons">
                                        {sizes.length > 0 ?
                                            sizeBody(sizes)
                                            :
                                            <p className='no-products'
                                               style={{marginTop: 0, textAlign: "left", fontSize: 18}}>
                                                No Sizes Found
                                            </p>
                                        }
                                    </div>

                                    {/* Count */}
                                    <h2 className="my-4">Items to Show</h2>
                                    <div className="size-circle-buttons">
                                        <button
                                            className={(pageSize === 12 ? "selected-size-button " : "") + "btn me-2 volume-btn rounded-circle btn-outline-dark"}
                                            style={{padding: "2% 4%"}}
                                            onClick={() => setPageSize(12)}>
                                            12
                                        </button>
                                        <button
                                            className={(pageSize === 24 ? "selected-size-button " : "") + "btn me-2 volume-btn rounded-circle btn-outline-dark"}
                                            style={{padding: "2% 4%"}}
                                            onClick={() => setPageSize(24)}>
                                            24
                                        </button>
                                        <button
                                            className={(pageSize === 36 ? "selected-size-button " : "") + "btn me-2 volume-btn rounded-circle btn-outline-dark"}
                                            style={{padding: "2% 4%"}}
                                            onClick={() => setPageSize(36)}>
                                            36
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn white-button-blue-border" data-bs-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default ProductsPage;
