import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProductDetails from "../components/ProductDetails";
import Loading from "../components/Loading";

import {addProductToList, loadCategories, loadProductsFromTag, loadTestimonials} from "../common/Common";

import Kettle from '../assets/images/kettle.png';
import AirFryer from '../assets/images/airfryer.png';
import Speakers from '../assets/images/speakers.png';
import Brand_Logos from '../assets/images/BrandLogos.svg';
import Brand_Logos1 from '../assets/images/BrandLogos1.svg';
import Brand_Logos2 from '../assets/images/BrandLogos2.svg';
import Brand_Logos3 from '../assets/images/BrandLogos3.svg';
import Component_1 from '../assets/images/Component1.png';
import Component_2 from '../assets/images/Component2.png';
import Component_3 from '../assets/images/Component3.png';

import '../assets/css/Home.css';

function HomePage({parentUpdateCount}) {
    const [testimonials, setTestimonials] = useState([]),
        [categories, setCategories] = useState([]),
        [newProducts, setNewProducts] = useState([]),
        [featuredProducts, setFeaturedProducts] = useState([]),
        [selectedProduct, setSelectedProduct] = useState(null),
        [loadingContent, setLoadingContent] = useState(true),
        [windowWidth, setWindowWidth] = useState(0),
        productSliderSettings = {
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
        categorySliderSettings = {
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 350,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
        partnerSliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ]
        },
        testimonialSliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        loadTestimonials(4).then(data => {
            setTestimonials(data);
            loadCategories().then(data => {
                setCategories(data);
                loadProductsFromTag('New').then(data => {
                    setNewProducts(data);
                    loadProductsFromTag('Featured').then(data => {
                        setFeaturedProducts(data);
                        setLoadingContent(false);
                    }).catch(() => null);
                }).catch(() => null);
            }).catch(() => null);
        }).catch(() => null);
    }, []);

    const resizeWindow = () => {
        setWindowWidth(window.outerWidth);
    };

    const categoryBody = (categories) => {
        let body = [];
        categories.forEach((category, index) => {
            body.push(
                <Link to={'/products'}
                      state={{
                          productCategoryTag: {
                              id: category.product_category_tag.id,
                              name: category.product_category_tag.name
                          }
                      }} className="nav-link category"
                      key={"Category" + index}>
                    <div>
                        <img src={category.image_url} alt={category.name}/>
                        <img src={category.hover_image_url} alt={category.name} className="hover-image"/>
                    </div>
                    <h1 className="category-name">{category.name}</h1>
                </Link>
            );
        });

        let categorySlideCount = 6;
        if (windowWidth <= 350) {
            categorySlideCount = 1;
        } else if (windowWidth <= 550) {
            categorySlideCount = 2;
        } else if (windowWidth <= 992) {
            categorySlideCount = 3;
        } else if (windowWidth <= 1200) {
            categorySlideCount = 4;
        } else if (windowWidth <= 1400) {
            categorySlideCount = 5;
        }
        if (body.length < categorySlideCount) {
            for (let i = body.length; i < categorySlideCount; i++) {
                body.push(<div className="category" key={"Category" + i}/>);
            }
        }

        return body;
    }

    const addToList = (product) => {
        let tempProduct = product;
        tempProduct.selectedQuantity = 1;
        tempProduct.size = JSON.parse(product.sizes)[0];
        tempProduct.color = JSON.parse(product.colors)[0];
        addProductToList(tempProduct);
        parentUpdateCount();
    }

    const productBody = (products, tag) => {
        let body = [];
        products.forEach((product, index) => {
            body.push(
                <div className="product" key={"Product" + index}>
                    <div className="card text-center">
                        <div className="card-header"
                             style={{height: "272px"}}>
                            <label className="cardlbl border float-start"
                                   style={{backgroundColor: "#E1211D"}}>{tag}</label>
                            <div className="text-end border-0">
                                <input type="checkbox" className="checkbox" id="checkbox1"/>
                            </div>
                            <div className="text-end">
                                <button className="btn Expand-btn" data-bs-toggle="modal"
                                        data-bs-target="#productModal" onClick={() => setSelectedProduct(product)}/>
                            </div>
                            <img src={product.image_url} alt={product.name}
                                 style={{overflow: "hidden", width: "100%", height: "60%", objectFit: "contain"}}/>
                        </div>
                        <div className="card-body">
                            <p className="card-title">{product.name}</p>
                            <h5 className="card-price">{product.currency} {product.price}</h5>
                            <Link to={"request-quote"}>
                                <button className="btn btn-lg Pricetag blue-button" onClick={() => addToList(product)}>
                                    Get a quote
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20"
                                         fill="currentColor" className="bi bi-tag flip-tag-icon"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
                                        <path
                                            d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
                                    </svg>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        });

        let productSlideCount = 4;
        if (windowWidth <= 550) {
            productSlideCount = 1;
        } else if (windowWidth <= 992) {
            productSlideCount = 2;
        } else if (windowWidth <= 1200) {
            productSlideCount = 3;
        }
        if (body.length < productSlideCount) {
            for (let i = body.length; i < productSlideCount; i++) {
                body.push(<div className="product" key={"Product" + i}/>);
            }
        }

        return body;
    }

    const testimonialBody = (testimonials) => {
        let body = [];
        if (windowWidth <= 992) {
            for (let i = 0; i < testimonials.length; i++) {
                body.push(
                    <div key={"Testimonial" + i}>
                        <div className="testimonial" style={{marginRight: 0}}>
                            <div className="d-flex flex-row">
                                <div className="card-top text-center">
                                    <img src={testimonials[i].image_url}
                                         alt={testimonials[i].name}
                                         style={{borderRadius: 37, width: 75, height: 75}}/>
                                </div>
                                <div>
                                    <h1 className="ml-3 feedbacks-name">{testimonials[i].name}</h1>
                                    <h5 className="text-start ml-3 feedbacks-date">{testimonials[i].date}</h5>
                                </div>
                            </div>
                            <div className="testimonial-message-container">
                                <p className="feedbacks-text text-start">{testimonials[i].message}</p>
                            </div>
                        </div>
                        {++i < testimonials.length &&
                        <div className="testimonial" style={{marginTop: "5%", marginRight: 0}}>
                            <div className="d-flex flex-row">
                                <div className="card-top text-center">
                                    <img src={testimonials[i].image_url}
                                         alt={testimonials[i].name}
                                         style={{borderRadius: 37, width: 75, height: 75}}/>
                                </div>
                                <div>
                                    <h1 className="ml-3 feedbacks-name">{testimonials[i].name}</h1>
                                    <h5 className="text-start ml-3 feedbacks-date">{testimonials[i].date}</h5>
                                </div>
                            </div>
                            <div className="testimonial-message-container">
                                <p className="feedbacks-text text-start">{testimonials[i].message}</p>
                            </div>
                        </div>
                        }
                    </div>
                );
            }
        } else {
            testimonials.forEach((testimonial, index) => {
                body.push(
                    <div className="testimonial" key={"Testimonial" + index}>
                        <div className="d-flex flex-row">
                            <div className="card-top text-center">
                                <img src={testimonial.image_url}
                                     alt={testimonial.name}
                                     style={{borderRadius: 37, width: 75, height: 75}}/>
                            </div>
                            <div>
                                <h1 className="ml-3 feedbacks-name">{testimonial.name}</h1>
                                <h5 className="text-start ml-3 feedbacks-date">{testimonial.date}</h5>
                            </div>
                        </div>
                        <div className="testimonial-message-container">
                            <p className="feedbacks-text text-start">{testimonial.message}</p>
                        </div>
                    </div>
                );
            });
        }
        return body;
    }

    if (!loadingContent) {
        return (
            <div>
                <div style={{backgroundColor: "white", marginTop: "5vh"}}>
                    <div className="d-flex justify-content-center mb-5 container">
                        <div className="border-0 Home-image">
                            <div>
                                <h5 className="home-title mb-3">HOME APPLIANCES</h5>
                                <h1 className="home-subtitle mb-5">
                                    Enhance your daily life with our cutting-edge appliances
                                </h1>
                                <Link to="/products">
                                    <button className="btn shop-now-btn btn-lg red-button">EXPLORE NOW</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Categories slide show */}

                    <div className="border-0 rounded-3 category-container" style={{backgroundColor: "#F9F9F9"}}>
                        <div className="container">
                            <div className="d-flex justify-content-center">
                                <h1 className="categories mb-5  mt-5">Categories</h1>
                            </div>
                            {categories.length > 0 ?
                                <Slider {...categorySliderSettings}>
                                    {categoryBody(categories)}
                                </Slider>
                                : <p className='no-products' style={{marginTop: 0}}>No Categories Found</p>
                            }
                        </div>
                    </div>

                    {/*New Arrival Slide Show*/}

                    <div className="d-flex justify-content-center mb-5 mt-2 container">
                        <h1 className="new-arrivals ">New Arrivals</h1>
                    </div>

                    <div className="product-container">
                        <div className="container">
                            {newProducts.length > 0 ?
                                <Slider {...productSliderSettings}>
                                    {productBody(newProducts, "New")}
                                </Slider>
                                : <p className='no-products'>No Products Found</p>
                            }
                        </div>
                    </div>

                    {/* PRODUCTs */}

                    <div className="products row justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="pc-version card" style={{backgroundColor: "#1F1F1F"}}>
                                <img src={Kettle} alt='kettle' className="bannerimg1"/>
                                <div className="card-body ps-5 pb-5">
                                    <h5 className="bannerlbl">KITCHEN APPLIANCES</h5>
                                    <h3 className="bannername">Electric Kettles</h3>
                                    <Link to="/products">
                                        <button className="btn border-2 mb-3 bannerbtn white-border-button">
                                            Explore Range
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="mobile-version card"
                                 style={{backgroundColor: "#1F1F1F", height: "100%", width: "100%"}}>
                                <div className="row card-body">
                                    <div className="col-sm-5" style={{justifyItems: "center"}}>
                                        <img src={Kettle} alt='kettle' className="bannerimg1" style={{
                                            display: "block",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            width: "100%"
                                        }}/>
                                    </div>
                                    <div className="col-sm-7" style={{alignSelf: "center"}}>
                                        <h5 className="bannerlbl ml-5">KITCHEN APPLIANCES</h5>
                                        <h3 className="bannername ml-5">Electric Kettles</h3>
                                        <Link to="/products">
                                            <button className="btn border-2 mb-3 ml-5 bannerbtn white-border-button">
                                                Explore Range
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="pc-version card" style={{backgroundColor: "#1F1F1F"}}>
                                        <div className="row card-body">
                                            <div className="col-sm-5 ml-4" style={{alignSelf: "center"}}>
                                                <h5 className="bannerlbl">NEW ARRIVALS</h5>
                                                <h3 className="bannername">AirFryers</h3>
                                                <Link to="/products">
                                                    <button className="btn border-2 mb-3 bannerbtn white-border-button">
                                                        Explore Range
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="col-sm-6 bannerimg-container">
                                                <img src={AirFryer} alt='airfryer' className="bannerimg"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobile-version card"
                                         style={{backgroundColor: "#1F1F1F", height: "100%", width: "100%"}}>
                                        <div className="row card-body">
                                            <div className="col-sm-7" style={{alignSelf: "center"}}>
                                                <h5 className="bannerlbl ml-5">NEW ARRIVALS</h5>
                                                <h3 className="bannername ml-5">AirFryers</h3>
                                                <Link to="/products">
                                                    <button
                                                        className="btn border-2 mb-3 ml-5 bannerbtn white-border-button">
                                                        Explore Range
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="col-sm-5" style={{justifyItems: "center"}}>
                                                <img src={AirFryer} alt='airfryer' className="bannerimg1" style={{
                                                    display: "block",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    width: "100%"
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="pc-version card" style={{backgroundColor: "#1F1F1F"}}>
                                        <div className="row card-body">
                                            <div className="col-sm-5 ml-4" style={{alignSelf: "center"}}>
                                                <h5 className="bannerlbl">MULTIMEDIA</h5>
                                                <h3 className="bannername">Speakers</h3>
                                                <Link to="/products">
                                                    <button className="btn border-2 mb-3 bannerbtn white-border-button">
                                                        Explore Range
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="col-sm-6">
                                                <img src={Speakers} alt='speakers' className="bannerimg speaker-image"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobile-version card"
                                         style={{backgroundColor: "#1F1F1F", height: "100%", width: "100%"}}>
                                        <div className="row card-body">
                                            <div className="col-sm-7" style={{alignSelf: "center"}}>
                                                <h5 className="bannerlbl ml-5">MULTIMEDIA</h5>
                                                <h3 className="bannername ml-5">Speakers</h3>
                                                <Link to="/products">
                                                    <button
                                                        className="btn border-2 mb-3 ml-5 bannerbtn white-border-button">
                                                        Explore Range
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="col-sm-5" style={{justifyItems: "center"}}>
                                                <img src={Speakers} alt='speakers' className="bannerimg1" style={{
                                                    display: "block",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    width: "100%"
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Featured Product slide show */}

                    <div className="d-flex justify-content-center mt-5 pt-5 container">
                        <h1 className="featured-products mb-5">Featured Products</h1>
                    </div>
                    <div className="product-container">
                        <div className="container">
                            {featuredProducts.length > 0 ?
                                <Slider {...productSliderSettings}>
                                    {productBody(featuredProducts, "Featured")}
                                </Slider>
                                : <p className='no-products'>No Products Found</p>
                            }
                        </div>
                    </div>

                    <div className="container stat-container" style={{marginBottom: "10%"}}>
                        <div>
                            <h1 className="my-5"
                                style={{fontSize: "32px", textAlign: "center", width: "60%", margin: "auto", paddingTop:"30px"}}>
                                Empowering Your Life with Smarter Solutions
                            </h1>
                            <div className="col-md-12">
                                <p className="para-text mb-4 text-align-center" style={{textAlign: "center"}}>
                                    Enhance your life through innovative, intuitive electronic appliances that help make
                                    your life easier, more enjoyable, and more connected.
                                </p>
                            </div>
                            <div className="row mt-4" style={{textAlign: "center"}}>
                                <div className="col-12 col-md-4">
                                    <p className="mb-0"
                                       style={{color: "#11448A", fontWeight: "700", fontSize: "56px"}}>1997</p>
                                    <p className="para-text mb-3"
                                       style={{fontWeight: "500", textAlign: "center"}}>Start of journey</p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <p className="mb-0"
                                       style={{color: "#11448A", fontWeight: "700", fontSize: "56px"}}>1M+</p>
                                    <p className="para-text mb-3"
                                       style={{fontWeight: "500", textAlign: "center"}}>Orders</p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <p className="mb-0"
                                       style={{color: "#11448A", fontWeight: "700", fontSize: "56px"}}>1.5K+</p>
                                    <p className="para-text mb-3"
                                       style={{fontWeight: "500", textAlign: "center"}}>Users</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Mission and Vision */}

                    <div className="MV row">
                        <div className="col-12 col-md-7 MV-photo">
                        </div>
                        <div className="col-12 col-md-5 justify-content-center">
                            <div className="row">
                                <div className="col-12 col-md-12 border-0 rounded-3"
                                     style={{backgroundColor: "#F6F8FF", padding: "2% 5%"}}>
                                    <h1 className="text-center mv-name mt-5">Our Mission</h1>
                                    <p className="text-center mv-mv mb-5 special-width">
                                        Offer smarter solutions that inspire convenience, efficiency, and shape a future
                                        where technology empowers everyone to live their best life.
                                    </p>
                                </div>
                                <div className="col-12 col-md-12 border-0 rounded-3 mt-5"
                                     style={{backgroundColor: "#F6F8FF", padding: "2% 5%"}}>
                                    <h1 className="text-center mv-name mt-5">Our Vision</h1>
                                    <p className="text-center mv-mv mb-5 special-width">
                                        To make technology accessible to all and offer reliable electronic appliances
                                        that are easy to use, and designed to simplify everyday life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Customer feedbacks */}
                    <div className="mt-5 d-flex justify-content-center mb-5 mt-4 partner-container">
                        <div className="container">
                            <Slider {...partnerSliderSettings}>
                                <div className="brandslide">
                                    <img src={Brand_Logos1} alt='' style={{margin: "auto", height: "5em", width: "100%"}}/>
                                </div>
                                <div className="brandslide">
                                    <img src={Brand_Logos2} alt='' style={{margin: "auto", height: "5em", width: "100%"}}/>
                                </div>
                                <div className="brandslide">
                                    <img src={Brand_Logos} alt='' style={{margin: "auto", height: "5em", width: "100%"}}/>
                                </div>
                                <div className="brandslide">
                                    <img src={Brand_Logos3} alt='' style={{margin: "auto", height: "5em", width: "100%"}}/>
                                </div>
                            </Slider>
                        </div>
                    </div>

                    <div className="container testimonial-container">
                        <div className="mt-5 d-flex justify-content-center mb-5">
                            <h1 className="feedbacks-title">See what our customers have to say</h1>
                        </div>
                        <Slider {...testimonialSliderSettings}>
                            {testimonialBody(testimonials)}
                        </Slider>
                    </div>
                    <div className="container benefit-container">
                        <div className="benefits row">
                            <div className="text-center col-12 col-md-4">
                                <img src={Component_1} alt='' className=""/>
                                <h5 className="endframe-name mt-3">Extensive Range </h5>
                                <h6 className="endframe">
                                    Explore quality home appliances with ease
                                </h6>
                            </div>
                            <div className="text-center  col-12 col-md-4">
                                <img src={Component_2} alt='' className=""/>
                                <h5 className="endframe-name mt-3">Quality Support </h5>
                                <h6 className="endframe">
                                    Our commitment doesn't end With Your purchase
                                </h6>
                            </div>
                            <div className="text-center col-12 col-md-4">
                                <img src={Component_3} alt='' className=""/>
                                <h5 className="endframe-name mt-3">Competitive Pricing </h5>
                                <h6 className="endframe">
                                    Get great value with cost-effective pricing
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="productModal" tabIndex="-1" aria-labelledby="productModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "80%"}}>
                        <div className="modal-content">
                            <div className="modal-header" style={{border: "none"}}>
                                <button type="button" className="btn btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <ProductDetails product={selectedProduct} modal={true}
                                                parentUpdateCount={parentUpdateCount}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default HomePage;
